/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Card, Heading, Text, Flex } from "theme-ui"
import { useInView } from "react-intersection-observer"

import theme from "../gatsby-plugin-theme-ui"

import { JournazineDataPropTypes } from "../utils/propTypes"
import { Link } from "./Link"
import { ResponsiveImage } from "./ResponsiveImage"
import { RichText } from "./RichText"
import { Divider } from "./Divider"
import { DividerType } from "../hooks/divider"

const JournazineBriefCardPropTypes = {
  isEmphasized: PropTypes.bool,
  view_url: PropTypes.string,
  buy_url: PropTypes.string,
  ...JournazineDataPropTypes,
  styles: PropTypes.object,
}

const JournazineBriefCardWithPrintPreview = ({
  publication_date,
  issue_title,
  short_issue_description,
  view_url,
  buy_url,
  preview_link,
  styles,
}) => {
  const { inView, ref } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  const finalBuyUrl = buy_url

  return (
    <Card
      variant="journazine"
      ref={ref}
      sx={{
        height: [null, null, "800px", "600px"],
        mt: 4,
        flexDirection: [null, null, "column-reverse", "row"],
        ...styles,
      }}
    >
      <div
        sx={{
          height: "100%",
          bg: "darkSoilBrown",
          flex: 2,
          color: "white",
          textAlign: "left",
          p: 4,
        }}
      >
        <Text variant="fineprint">{publication_date}</Text>
        <Heading variant="headingWhite" sx={{ fontSize: [3, 3, 4] }}>
          {issue_title.text}
        </Heading>
        <RichText text={short_issue_description} />
        <Link
          variant="buttonSandYellowTransparent"
          to={view_url}
          sx={{
            ml: 0,
            mb: 3,
            minWidth: "60%",
            fontSize: 3,
            textAlign: "center",
          }}
        >
          View Issue
        </Link>
        <Divider type={DividerType.DEFAULT_YELLOW} styles={{ width: "100%" }} />
        <Link variant="buttonPrimary" to={finalBuyUrl} sx={{ ml: 0, mt: 3 }}>
          Buy
        </Link>
      </div>
      <Flex sx={{ flex: 5, bg: "white", height: "100%", width: "100%" }}>
        {inView ? (
          <iframe
            title={`${issue_title.text}-preview`}
            loading="lazy"
            src={preview_link.url}
            allowFullScreen={true}
            frameBorder={0}
            sx={{ flex: 1 }}
          />
        ) : null}
      </Flex>
    </Card>
  )
}
JournazineBriefCardWithPrintPreview.propTypes = JournazineBriefCardPropTypes

export const JournazineBriefCard = ({
  isEmphasized,
  cover_image,
  publication_date,
  issue_title,
  short_issue_description,
  issue_description,
  view_url,
  buy_url,
  preview_link,
  styles,
}) => {
  if (preview_link.url && isEmphasized) {
    return (
      <JournazineBriefCardWithPrintPreview
        publication_date={publication_date}
        issue_title={issue_title}
        short_issue_description={short_issue_description}
        view_url={view_url}
        buy_url={buy_url}
        preview_link={preview_link}
        styles={styles}
      />
    )
  }
  const finalBuyUrl = buy_url
  return (
    <Card
      variant="journazine"
      sx={{
        height: "100%",
        flexDirection: isEmphasized ? "row" : "column",
        ...styles,
      }}
    >
      <ResponsiveImage
        image={cover_image}
        sx={{
          width: isEmphasized
            ? [
                null,
                null,
                `calc(650px * ${cover_image.gatsbyImageData.width} / ${cover_image.gatsbyImageData.height}) !important`,
              ]
            : ["95% !important", null],
          height: isEmphasized ? "650px !important" : "auto",
        }}
      />
      <div
        id="issue-description"
        sx={{
          flex: isEmphasized ? 1 : "auto",
          height: isEmphasized ? "100%" : "auto",
          bg: "darkSoilBrown",
          color: "white",
          textAlign: "left",
          p: 4,
          ml: [`-${(100 - theme.mobilePageContentWidth) / 2}vw`, 0, 0, 0],
          mt: isEmphasized ? 0 : "-40px",
          position: "relative",
          zIndex: 1,
          transition: "all 0.2s ease-in",
        }}
      >
        <Text variant="fineprint">{publication_date}</Text>
        <Heading
          variant="headingWhite"
          sx={{
            fontSize: [3, 3, 4],
          }}
        >
          {issue_title.text}
        </Heading>
        <RichText
          text={isEmphasized ? issue_description : short_issue_description}
        />
        <Link
          variant="buttonSandYellowTransparent"
          to={view_url}
          sx={{
            ml: 0,
            mb: 3,
            minWidth: isEmphasized ? "200px" : "60%",
            fontSize: 3,
            textAlign: "center",
          }}
        >
          View Issue
        </Link>
        <Divider
          type={DividerType.DEFAULT_YELLOW}
          styles={{ width: isEmphasized ? "40%" : "100%" }}
        />
        <Link
          variant="buttonPrimary"
          to={finalBuyUrl}
          sx={{ ml: 0, mt: 3, px: 3 }}
        >
          Buy
        </Link>
        {preview_link.url ? (
          <Link
            variant="buttonPrimaryTransparent"
            to={preview_link.url}
            sx={{ mt: 3, px: 2 }}
          >
            Print Preview
          </Link>
        ) : null}
      </div>
    </Card>
  )
}
JournazineBriefCard.propTypes = JournazineBriefCardPropTypes
