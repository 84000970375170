/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import { transformJournazinesData } from "../utils/journazineUtils"

export const latestJournazines = () => {
  const staticData= useStaticQuery(
    graphql`
      query LatestJournazines {
        allPrismicJournazine(
          limit: 3
          sort: { fields: [data___publication_date], order: [DESC] }
        ) {
          edges {
            node {
              ...Journazine
            }
          }
        }
      }
    `
  )

  const { data } = useMergePrismicPreviewData(staticData)
  return transformJournazinesData(data.allPrismicJournazine)
}
