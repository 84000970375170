/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import { transformArticlesData } from "../utils/articleUtils"

export const latestFeaturedArticle = () => {
  // For articles which are not backdated (ie. data__publication_date is null)
  // It looks like descending the sort will always place them in front
  // This behaviour should be fine since the publication_date field should only
  // be set if we want to backdate articles (we should never "forward-date" articles manually)
  const staticData = useStaticQuery(
    graphql`
      query LatestFeaturedArticle {
        allPrismicArticle(
          limit: 1
          filter: { data: { is_featured: { eq: true } } }
          sort: {
            fields: [data___publication_date, first_publication_date]
            order: [DESC, DESC]
          }
        ) {
          edges {
            node {
              ...ArticleCard
            }
          }
        }
      }
    `
  )
  const { data } = useMergePrismicPreviewData(staticData)
  return transformArticlesData(data.allPrismicArticle)
}
