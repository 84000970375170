/** @jsx jsx */

import { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { jsx, Text, Heading, Paragraph, Flex, Box } from "theme-ui"
import Slider from "react-slick"
import { transparentize } from "@theme-ui/color"

import { GatsbyImagePropType } from "../utils/propTypes"
import { Link } from "../components/Link"
import { renderArrowProps } from "./CarouselArrow"
import { CarouselIndicator } from "./CarouselIndicator"
import { ResponsiveImage } from "./ResponsiveImage"
import { heightFromDeviceHeight } from "../utils/styleUtils"

export const FeatureItemPropTypes = {
  label: PropTypes.string,
  fallbackMobileImage: PropTypes.shape({
    gatsbyImageData: GatsbyImagePropType,
  }),
  image: PropTypes.shape({
    gatsbyImageData: GatsbyImagePropType,
    thumbnails: PropTypes.shape({
      mobile_portrait: PropTypes.shape({
        gatsbyImageData: GatsbyImagePropType,
      }),
      mobile_landscape: PropTypes.shape({
        gatsbyImageData: GatsbyImagePropType,
      }),
    }),
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      button_label: PropTypes.shape({
        text: PropTypes.string,
      }),
      button_link: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
}

export const MobileFeatureItem = ({
  label,
  image,
  title,
  description,
  buttons,
}) => {
  return (
    <ResponsiveImage
      image={image}
      isBackgroundImage={true}
      sx={{
        backgroundPosition: "top",
        ...heightFromDeviceHeight(1),
        display: ["flex", "flex", "none"],
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Flex
        sx={{
          background: t => `
      linear-gradient(
        to bottom,
        ${transparentize("darkSoilBrown", 1)(t)} 0%,
        ${transparentize("darkSoilBrown", 0.2)(t)} 80%,
        ${transparentize("darkSoilBrown", 0)(t)}
      )
    `,
          px: "10vw",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Text
          sx={{
            textTransform: "uppercase",
            mb: 3,
            color: "white",
            fontSize: 2,
          }}
        >
          {label}
        </Text>
        <Heading
          sx={{
            fontSize: 4,
            my: 3,
            color: "white",
          }}
        >
          {title}
        </Heading>
        <Paragraph
          sx={{
            color: "white",
            fontSize: 2,
            // Ensure we truncate multiline text with and ellipsis
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 5,
            overflow: "hidden",
            mb: 3,
          }}
        >
          {description}
        </Paragraph>
        <Flex sx={{ justifyContent: "center", flexDirection: "row" }}>
          {buttons.map((button, i) => (
            <Link
              variant={
                i % 2 === 0 ? "buttonPrimary" : "buttonPrimaryTransparent"
              }
              key={i}
              to={button.button_link.url}
            >
              {button.button_label.text}
            </Link>
          ))}
        </Flex>
      </Flex>
    </ResponsiveImage>
  )
}

MobileFeatureItem.propTypes = FeatureItemPropTypes

export const DesktopFeatureItem = ({
  label,
  image,
  title,
  description,
  buttons,
}) => {
  return (
    <Flex
      sx={{
        mx: "auto",
        height: [null, null, "60vh", "70vh"],
        flexDirection: "row",
        display: ["none", "none", "flex"],
      }}
    >
      <ResponsiveImage
        predicted="tablet"
        image={image}
        sx={{
          flex: 2,
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
        }}
      />
      <Flex
        sx={{
          background: t => `
          linear-gradient(
            to bottom, ${t.colors.soilBrown}, ${t.colors.darkSoilBrown}
          )
          `,
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          textAlign: "left",
          p: 6,
        }}
      >
        <Text
          sx={{
            textTransform: "uppercase",
            mb: 3,
            color: "white",
            fontSize: 3,
          }}
        >
          {label}
        </Text>
        <Heading
          sx={{
            fontSize: 4,
            my: 4,
            color: "white",
          }}
        >
          {title}
        </Heading>
        <Paragraph
          sx={{
            color: "white",
            fontSize: 2,
            // Ensure we truncate multiline text with and ellipsis
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: [null, null, 6, 7],
            overflow: "hidden",
            mb: 4,
          }}
        >
          {description}
        </Paragraph>
        <Flex
          // Add additional bottom margin to allow a comfortable space above
          // the carousel indicators that are rendered outside this container's parent
          sx={{ justifyContent: "flex-start", flexDirection: "row", mb: 6 }}
        >
          {buttons.map((button, i) => (
            <Link
              variant={
                i % 2 === 0 ? "buttonPrimary" : "buttonPrimaryTransparent"
              }
              key={i}
              to={button.button_link.url}
            >
              {button.button_label.text}
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
DesktopFeatureItem.propTypes = FeatureItemPropTypes

const CarouselIndicators = ({ indicators }) => {
  // For tablet and desktop:
  // To ensure that the indicators show up aligned with the text content
  // on the left, we replicate the styling for alignment used in DesktopFeatureItem:
  // using a parent flexbox,
  // a left sibling that has a flex-grow of 2,
  // and setting flex-grow to 1 as well as paddingX of 6 theme units
  // on the indicator container itself

  // For mobile:
  // we set flex-grow of the left sibling to 0 so that the indicator container
  // will take up the full width of the parent
  return (
    <Flex sx={{ margin: "auto", justifyContent: "flex-end" }}>
      <div sx={{ flex: [0, 0, 2] }} />
      <ul
        className="slick-dots"
        sx={{
          position: "inherit",
          flex: 1,
          display: "flex",
          justifyContent: ["center", "center", "flex-start", "flex-start"],
          px: 6,
          my: 0,
          mt: -6,
          zIndex: 1,
        }}
      >
        {indicators}
      </ul>
    </Flex>
  )
}
CarouselIndicators.propTypes = {
  indicators: PropTypes.node,
}

export const FeatureCarousel = ({ featureItems }) => {
  const [selectedIndex, updateSelectedIndex] = useState(0)

  return (
    <Box sx={{ px: [0, 0, 4] }}>
      <Slider
        arrows={true}
        {...renderArrowProps}
        dots={true}
        customPaging={index => <CarouselIndicator index={index} />}
        appendDots={dots => <CarouselIndicators indicators={dots} />}
        infinite={true}
        autoplay={false}
        swipeToSlide={true}
        slidesToShow={1}
        initialSlide={selectedIndex}
        beforeChange={(oldIndex, newIndex) => {
          if (selectedIndex !== newIndex) updateSelectedIndex(newIndex)
        }}
      >
        {featureItems.map(featureItem => {
          return (
            <Fragment key={featureItem.title}>
              <MobileFeatureItem {...featureItem} />
              <DesktopFeatureItem {...featureItem} />
            </Fragment>
          )
        })}
      </Slider>
    </Box>
  )
}
FeatureCarousel.propTypes = {
  featureItems: PropTypes.arrayOf(PropTypes.shape(FeatureItemPropTypes)),
}
