/** @jsx jsx */

import { jsx, Heading } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { latestJournazines } from "../../hooks/latestJournazines"
import { PrismicRichTextFieldPropType } from "../../utils/propTypes"
import { MediaMatcher } from "../MediaMatcher"
import { RichText } from "../RichText"
import { MobileItemsCarousel } from "../MobileItemsCarousel"
import { JournazineBriefCard } from "../JournazineBriefCard"

export const TheSauce = ({ description }) => {
  const journazines = latestJournazines()

  return (
    <div
      sx={{
        my: 7,
        textAlign: ["left", "left", "center"],
      }}
    >
      <Heading
        variant="headingOlive"
        sx={{
          ...theme.basePageStyles.contentContainer,
          mb: 2,
        }}
      >
        The Sauce
      </Heading>
      <RichText
        sxOverrides={{
          ...theme.basePageStyles.contentContainer,
        }}
        text={description}
      />
      <MediaMatcher.ServerRender predicted="mobilePortrait">
        <MediaMatcher.Matcher
          mobilePortrait={
            <MobileItemsCarousel
              items={journazines}
              component={JournazineBriefCard}
              itemProps={{
                styles: {
                  mr: 5,
                  "#issue-description:not(.slick-current *)": {
                    opacity: 0,
                  },
                },
              }}
            />
          }
          tablet={
            <JournazineBriefCard
              {...journazines[0]}
              isEmphasized={true}
              styles={{ ...theme.basePageStyles.contentContainer }}
            />
          }
        />
      </MediaMatcher.ServerRender>
    </div>
  )
}
TheSauce.propTypes = {
  description: PrismicRichTextFieldPropType,
}
