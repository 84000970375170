import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { HomePageTemplate } from "./HomePageTemplate"
import linkResolver from "../utils/linkResolver"

export const query = graphql`
  query HomePageTemplate {
    prismicHomePage {
      _previewable
      type
      data {
        body {
          ... on PrismicHomePageDataBodyFeaturedContent {
            primary {
              label
              image {
                alt
                gatsbyImageData(
                  width: 800
                  height: 650
                  placeholder: BLURRED
                  imgixParams: { q: 30 }
                )
                thumbnails {
                  mobile_portrait {
                    gatsbyImageData(
                      height: 900
                      width: 420
                      placeholder: BLURRED
                      imgixParams: { q: 30 }
                    )
                  }
                  mobile_landscape {
                    gatsbyImageData(
                      width: 900
                      height: 420
                      placeholder: BLURRED
                      imgixParams: { q: 30 }
                    )
                  }
                }
              }
              title {
                text
              }
              description {
                text
              }
            }
            items {
              button_label {
                text
              }
              button_link {
                url
                id
              }
            }
          }
        }
        intro_section_title {
          text
        }
        intro {
          richText
        }
        intro_graphic {
          alt
          gatsbyImageData(width: 1920, placeholder: BLURRED)
          thumbnails {
            mobile_portrait {
              gatsbyImageData(width: 600, placeholder: BLURRED)
            }
            mobile_landscape {
              gatsbyImageData(width: 900, placeholder: BLURRED)
            }
          }
        }
        the_sauce_description {
          richText
        }
        meta_title
        meta_description
        language
        canonical_url {
          url
        }
        opengraph_image {
          url
        }
      }
    }
  }
`
export default withPrismicPreview(HomePageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
