/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Flex, Heading, Text } from "theme-ui"
import { RiSeedlingLine } from "react-icons/ri"

import theme from "../../gatsby-plugin-theme-ui"
import { Link } from "../Link"
import { ResponsiveImage } from "../ResponsiveImage"
import { GatsbyImagePropType } from "../../utils/propTypes"
import { latestFeaturedArticle } from "../../hooks/latestFeaturedArticle"
import { latestArticles } from "../../hooks/latestArticles"
import { ArticleBriefCard } from '../ArticleBriefCard';
import { MobileItemsCarousel } from '../MobileItemsCarousel';

export const MobileArticles = ({ introGraphic }) => {
  const articles = latestFeaturedArticle().concat(latestArticles()).slice(0, 5)

  return (
    <ResponsiveImage
      image={introGraphic}
      isBackgroundImage={true}
      sx={{
        backgroundPosition: "top",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        pt: ["380px", "500px"],
      }}
    >
      <Heading
        as="h1"
        variant="headingSandYellow"
        sx={{ mb: 3, ...theme.basePageStyles.contentContainer }}
      >
        {"What's Growing"}
      </Heading>
      <MobileItemsCarousel
        items={articles}
        component={ArticleBriefCard}
        itemProps={{ displayBadges: true }}
      />
      <Flex
        sx={{
          ...theme.basePageStyles.contentContainer,
        }}
      >
        <Link variant="buttonPrimary" to="/articles/1" sx={{ mt: 3, mb: 4 }}>
          <Flex sx={{ alignItems: "center" }}>
            <Text sx={{ mr: 1 }}>Enter the forest</Text>
            <RiSeedlingLine />
          </Flex>
        </Link>
      </Flex>
    </ResponsiveImage>
  )
}
MobileArticles.propTypes = {
  introGraphic: PropTypes.shape({
    gatsbyImageData: GatsbyImagePropType,
    thumbnails: PropTypes.shape({
      mobile: PropTypes.shape({
        gatsbyImageData: GatsbyImagePropType,
      }),
    }),
  }).isRequired,
}
