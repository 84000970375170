export const transformJournazineData = prismicJournazine => {
  return {
    ...prismicJournazine.data,
    uid: prismicJournazine.uid,
    view_url: prismicJournazine.url,
    buy_url: `/products${prismicJournazine.data.crystallize_product_catalogue_path}`,
  }
}

export const transformJournazinesData = allPrismicJournazine => {
  return allPrismicJournazine.edges.map(edge => {
    return transformJournazineData(edge.node)
  })
}
