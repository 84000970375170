/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Box } from "theme-ui"

import { Layout } from "../components/Layout"
import { extractBaseSeoFields } from "../utils/seoUtils"
import {
  GatsbyImagePropType,
  PrismicPlainTextFieldPropType,
  PrismicRichTextFieldPropType,
  SeoFieldsPropType,
  CategoryPropTypes,
} from "../utils/propTypes"
import {
  FeatureCarousel,
  FeatureItemPropTypes,
} from "../components/FeatureCarousel"
import { IntroAndArticlesSection } from "../components/home_page/IntroAndArticlesSection"
import { TopCategories } from "../components/home_page/TopCategories"
import { TheSauce } from "../components/home_page/TheSauce"

export const HomePageTemplate = ({ data, pageContext }) => {
  const {
    body,
    intro_section_title,
    intro,
    intro_graphic,
    the_sauce_description,
  } = data.prismicHomePage.data
  const featureItems = body.map(rawItem => ({
    ...rawItem.primary,
    title: rawItem.primary.title.text,
    description: rawItem.primary.description.text,
    label: rawItem.primary.label,
    buttons: rawItem.items,
  }))

  const seoProps = extractBaseSeoFields({
    ...data.prismicHomePage.data,
    type: data.prismicHomePage.type,
  })

  return (
    <Layout seoProps={seoProps}>
      <Box>
        <FeatureCarousel featureItems={featureItems} />
        <IntroAndArticlesSection
          title={intro_section_title}
          intro={intro}
          introGraphic={intro_graphic}
        />
        <TopCategories topCategories={pageContext.topCategories} />
        <TheSauce description={the_sauce_description} />
      </Box>
    </Layout>
  )
}

HomePageTemplate.propTypes = {
  data: PropTypes.shape({
    prismicHomePage: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.shape({
        ...SeoFieldsPropType,
        // Feature carousel
        body: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            primary: PropTypes.shape({
              image: FeatureItemPropTypes.image,
              title: PrismicPlainTextFieldPropType,
              description: PrismicPlainTextFieldPropType,
            }),
            items: FeatureItemPropTypes.buttons,
          })
        ),
        // Intro section
        intro_section_title: PrismicPlainTextFieldPropType.isRequired,
        intro: PrismicRichTextFieldPropType.isRequired,
        intro_graphic: PropTypes.shape({
          gatsbyImageData: GatsbyImagePropType,
          thumbnails: PropTypes.shape({
            mobile: PropTypes.shape({
              gatsbyImageData: GatsbyImagePropType,
            }),
          }),
        }).isRequired,
        // The Sauce section
        the_sauce_description: PrismicRichTextFieldPropType.isRequired,
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    topCategories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
  }),
}
