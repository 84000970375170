/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Flex } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { GatsbyImagePropType } from "../../utils/propTypes"
import { MediaMatcher } from "../MediaMatcher"
import { ResponsiveImage } from "../ResponsiveImage"
import { Intro, IntroPropTypes } from "./Intro"
import { MobileArticles } from "./MobileArticles"
import { DesktopArticles } from "./DesktopArticles"

const IntroAndArticlesSectionPropTypes = {
  ...IntroPropTypes,
  introGraphic: PropTypes.shape({
    gatsbyImageData: GatsbyImagePropType,
    thumbnails: PropTypes.shape({
      mobile: PropTypes.shape({
        gatsbyImageData: GatsbyImagePropType,
      }),
    }),
  }).isRequired,
}

const Mobile = ({ title, intro, introGraphic }) => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Intro
        title={title}
        intro={intro}
        containerStyles={{ ...theme.basePageStyles.contentContainer }}
      />
      <MobileArticles introGraphic={introGraphic} />
    </Flex>
  )
}
Mobile.propTypes = IntroAndArticlesSectionPropTypes

const Desktop = ({ title, intro, introGraphic }) => {
  return (
    <ResponsiveImage
      image={introGraphic}
      isBackgroundImage={true}
      sx={{
        backgroundPosition: "top",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Intro
        title={title}
        intro={intro}
        containerStyles={theme.basePageStyles.contentContainer}
      />
      <DesktopArticles />
    </ResponsiveImage>
  )
}
Desktop.propTypes = IntroAndArticlesSectionPropTypes

// Notes: due to the nature of the design on desktop, the easiest
// way to build the component is by combining the Intro and Articles
// sections so that they can share the same background image
// On mobile, because the graphic appears entirely below the intro text
// it can belong to the articles component alone

// The intro text should be kept to a short paragraph
// of about 200 characters, otherwise it will not scale nicely
// on tablet screens
export const IntroAndArticlesSection = props => {
  return (
    <MediaMatcher.ServerRender predicted="mobilePortrait">
      <MediaMatcher.Matcher
        mobilePortrait={<Mobile {...props} />}
        tablet={<Desktop {...props} />}
      />
    </MediaMatcher.ServerRender>
  )
}
