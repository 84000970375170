/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Heading, Flex, Text, Card } from "theme-ui"
import { transparentize } from "@theme-ui/color"
import Slider from "react-slick"

import theme from "../../gatsby-plugin-theme-ui"
import { CategoryPropTypes, GatsbyImagePropType } from "../../utils/propTypes"
import { MediaMatcher } from "../MediaMatcher"
import { ResponsiveImage } from "../ResponsiveImage"
import { Link } from "../Link"
import { Divider } from "../Divider"
import { DividerType } from "../../hooks/divider"

const CategoryCard = ({ uid, name, image, styles }) => (
  <Link variant="card" to={`/articles/category/${uid}/1`} sx={{ ...styles }}>
    <Card
      sx={{
        height: ["100px", "100px", "120px"],
        position: "relative",
      }}
    >
      <ResponsiveImage
        image={image}
        sizes={["200px", "400px", "200px"]}
        sx={{
          "&:before": {
            content: '""',
            display: "block",
            zIndex: "1",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            background: t => `
              linear-gradient(
                to bottom,
                ${transparentize("darkSoilBrown", 0.2)(t)} 0%,
                ${transparentize("darkSoilBrown", 0.5)(t)} 60%,
                ${transparentize("darkSoilBrown", 0.9)(t)}
              )
            `,
          },
          width: "100% !important",
          height: "100% !important",
        }}
        objectFit="cover"
      />
      <Text
        variant="subheading"
        sx={{
          zIndex: "2",
          textAlign: "left",
          position: "absolute",
          top: 3,
          left: 3,
          color: "white",
          width: "140px",
        }}
      >
        {name}
      </Text>
    </Card>
  </Link>
)
CategoryCard.propTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    gatsbyImageData: GatsbyImagePropType,
  }),
  styles: PropTypes.object,
}

const TopCategoriesPropTypes = {
  topCategories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
}

const MobileTopCategories = ({ topCategories }) => (
  <Slider
    arrows={false}
    dots={false}
    autoplay={false}
    infinite={false}
    swipeToSlide={true}
    slidesToShow={1.5}
    sx={{
      "div.slick-list": {
        pr: "0 !important",
        pl: `${(100 - theme.mobilePageContentWidth) / 2}% !important`,
      },
    }}
  >
    {topCategories.map(category => (
      <CategoryCard
        key={category.uid}
        {...category}
        styles={{ flex: 1, m: 3 }}
      />
    ))}
  </Slider>
)
MobileTopCategories.propTypes = TopCategoriesPropTypes

const DesktopTopCategories = ({ topCategories }) => (
  <Flex
    sx={{
      flexWrap: "wrap",
      justifyContent: "center",
      ...theme.basePageStyles.contentContainer,
    }}
  >
    {topCategories.map(category => (
      <CategoryCard
        key={category.uid}
        {...category}
        styles={{
          flex: 1,
          m: 2,
          minWidth: [null, null, "25%", "18%"],
          maxWidth: [null, null, "25%", "18%"],
        }}
      />
    ))}
  </Flex>
)
DesktopTopCategories.propTypes = TopCategoriesPropTypes

export const TopCategories = ({ topCategories }) => (
  <div sx={{ my: 6 }}>
    <Heading
      variant="headingOlive"
      sx={{
        ...theme.basePageStyles.contentContainer,
        mb: 2,
        textAlign: ["left", "left", "center"],
      }}
    >
      Emergent themes
    </Heading>
    <MediaMatcher.ServerRender predicted="mobilePortrait">
      <MediaMatcher.Matcher
        mobilePortrait={<MobileTopCategories topCategories={topCategories} />}
        tablet={<DesktopTopCategories topCategories={topCategories} />}
      />
    </MediaMatcher.ServerRender>

    <Divider
      type={DividerType.SEEDLING}
      styles={{
        ...theme.basePageStyles.contentContainer,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: ["80px", "100px", "100px", "150px"],
        mt: ["20px", "20px", "30px", "50px"],
        mb: [null, null, null, "10px"],
      }}
    />
  </div>
)
TopCategories.propTypes = TopCategoriesPropTypes
