/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Heading, Flex } from "theme-ui"

import {
  PrismicPlainTextFieldPropType,
  PrismicRichTextFieldPropType,
} from "../../utils/propTypes"
import { RichText } from "../RichText"

export const Intro = ({ title, intro, containerStyles }) => (
  <Flex
    sx={{
      ...containerStyles,
      mt: ["40px", "40px", "80px", "80px"],
    }}
  >
    <Flex
      sx={{
        flexDirection: "column",
        width: ["100%", "100%", "48%", "48%"],
      }}
    >
      <Heading as="h1" variant="headingOlive">
        {title.text}
      </Heading>
      <RichText sxOverrides={{ fontSize: [1, 1, 2, 3] }} text={intro} />
    </Flex>
  </Flex>
)
export const IntroPropTypes = {
  title: PrismicPlainTextFieldPropType.isRequired,
  intro: PrismicRichTextFieldPropType.isRequired,
  containerStyles: PropTypes.object,
}
Intro.propTypes = IntroPropTypes
