/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import { transformArticlesData } from "../utils/articleUtils"

export const latestArticles = () => {
  const staticData = useStaticQuery(
    graphql`
      query LatestArticles {
        allPrismicArticle(
          limit: 5
          filter: { data: { is_featured: { eq: false } } }
          sort: {
            fields: [data___publication_date, first_publication_date]
            order: [DESC, DESC]
          }
        ) {
          edges {
            node {
              ...ArticleCard
            }
          }
        }
      }
    `
  )
  const { data } = useMergePrismicPreviewData(staticData)
  return transformArticlesData(data.allPrismicArticle)
}
