/** @jsx jsx */

import { jsx, Flex, Heading, Text } from "theme-ui"
import { RiSeedlingLine } from "react-icons/ri"

import theme from "../../gatsby-plugin-theme-ui"
import { Link } from "../Link"
import { latestFeaturedArticle } from "../../hooks/latestFeaturedArticle"
import { latestArticles } from "../../hooks/latestArticles"
import { ArticleBriefCard } from "../ArticleBriefCard"
import { ItemsGrid } from "../ItemsGrid"

export const DesktopArticles = () => {
  const articles = latestFeaturedArticle().concat(latestArticles()).slice(0, 5)

  return (
    <Flex
      sx={{
        mt: [null, null, "450px", "280px"],
        flexDirection: "column",
        alignItems: "center",
        ...theme.basePageStyles.contentContainer,
      }}
    >
      <Heading as="h1" variant="headingSandYellow" sx={{ mb: 3 }}>
        {"What's Growing"}
      </Heading>
      <ItemsGrid
        items={articles}
        component={ArticleBriefCard}
        emphasizeFirst={true}
        itemProps={{ displayBadges: true }}
      />
      <Flex>
        <Link variant="buttonPrimary" to="/articles/1" sx={{ my: 6 }}>
          <Flex sx={{ alignItems: "center" }}>
            <Text sx={{ mr: 1 }}>Enter the forest</Text>
            <RiSeedlingLine />
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}
